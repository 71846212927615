<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="搜索：">
        <NInput
          maxlength="50"
          show-count
          clearable
          placeholder="请输入课程名称或课程ID："
          v-model:value="formValue.title"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <n-button
      type="primary"
      class="account-btn"
      @click="CancelMethod(1)"
      v-if="ownUrlPermission('course/create')"
      >新增课程</n-button
    >
    <div class="account-null-btn"></div>
    <NTabs
      type="line"
      @before-leave="handleTabBeforeLeave"
      @update:value="handleTabChange"
    >
      <n-tab-pane
        v-for="item in resourceTypes"
        :key="item.type"
        :name="item.type"
        :tab="item.name"
      >
      </n-tab-pane>
    </NTabs>
    <NAlert
      v-show="BookLength.length > 0"
      style="margin-bottom: 10px"
      type="primary"
    >
      已选择
      <span :style="{ color: '#33b6ff' }">{{ BookLength.length }}</span> 项数据
    </NAlert>
    <NButton
      type="primary"
      ghost
      @click="DistributionInstitution(1)"
      style="margin-bottom: 10px"
      v-if="ownUrlPermission('course/distribute')"
      >批量分发</NButton
    >
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :row-key="(row) => row.id"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageChange"
      @update:page="handlePageSizeChange"
      :default-checked-row-keys="DefaultValue"
      @update:checked-row-keys="handleCheck"
    />

    <!-- 新增课程name弹窗 -->
    <div id="StoragePopup">
      <n-modal
        class="custom-card"
        v-model:show="showModal"
        preset="card"
        :style="bodyStyle"
        title="新增课程"
        size="huge"
        :bordered="false"
      >
        <NForm
          require-mark-placement="left"
          label-align="right"
          label-placement="left"
        >
          <NFormItem label="课程名称：" show-require-mark>
            <NInput
              style="width: 250px"
              maxlength="50"
              placeholder="请输入课程名称："
              show-count
              clearable
              v-model:value="courseName"
            />
          </NFormItem>
        </NForm>
        <div class="storage-btns">
          <NSpace>
            <NButton @click="CancelMethod(0)">取消</NButton>
            <NButton type="primary" @click="NewlyAdded">确定</NButton>
          </NSpace>
        </div>
      </n-modal>
    </div>
    <!-- 新增课程name结束 -->

    <!-- 新增课程成功 -->
    <div id="coursePopup">
      <n-modal
        class="custom-card"
        v-model:show="showModal1"
        @after-leave="CancelMethod(0)"
        preset="card"
        :style="bodyStyle"
        title="信息提示"
        size="huge"
        :bordered="false"
      >
        <n-alert title="新增课程成功 !" type="success"></n-alert>
        <div class="storage-btns">
          <NSpace style="margin-top: 30px">
            <NButton @click="CancelMethod(0)">取消</NButton>
            <NButton
              type="primary"
              @click="addSuccess(true)"
              class="storage-btn-kc"
              >跳转课程建设, 继续完善</NButton
            >
          </NSpace>
        </div>
      </n-modal>
    </div>
    <!-- 存储弹窗结束 -->

    <!-- 分发组件 -->
    <Distribute
      ref="distributeRef"
      distribute-title="课程分发"
      @isrefresh="isrefresh"
      :TrainingBook="TrainingBook"
      :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
      @distribute-success="handlePageSizeChange(1)"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h, computed } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  GetCourseIndex,
  PostCourseState,
  PostCourseDelete,
  PostCourseCreate,
} from "@/api/course.js";
import { toRaw } from "@vue/reactivity";
import { useRouter } from "vue-router";
import PageLoading from "@/components/PageLoading/index.vue";
import { resourceEnum } from "@/enumerators/resource-types-map.js";
import Distribute from "@/components/CourseDistribution/Distribute.vue";
import ownUrlPermission from "@/utils/own-url-permission.js";

const resourceTypes = ref([
  { type: 2, name: "全部" },
  { type: 1, name: "使用中" },
  { type: 0, name: "已停用" },
]);

const props = defineProps({ 
  isrefresh: Boolean
}) 

// 切换监控
function handleTabBeforeLeave() {
  return !loading.value;
}

// 点击切换
function handleTabChange(name) {
  let index = Number(name);
  if (index == 2) {
    formValue.stop = ""; // 类型赋值
  } else {
    formValue.stop = index; // 类型赋值
  }
  // 初始化数据
  updateList(toRaw(formValue));
}

const DefaultValue = ref([]); // 选择默认值
const TrainingBook = ref([]);
const BookLength = ref([]);
// 点击选择时
const handleCheck = (idsArr) => {
  DefaultValue.value = idsArr;
  BookLength.value = idsArr;
  let data = [];
  TrainingBook.value.forEach((item3) => {
    data.push(item3.id);
  });
  Datas.value.forEach((item) => {
    idsArr.forEach((item1) => {
      if (item.id == item1) {
        let isdata = data.indexOf(item1);
        if (isdata < 0) {
          TrainingBook.value.push(toRaw(item));
        }
      }
    });
  });
};

// 是否刷新页面
const isrefresh = (is) => {
  console.log("是否刷新页面", is);
};

const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([]);
const columns = [
  { type: "selection" },
  {
    title: "课程ID",
    key: "id",
  },
  {
    title: "课程名称",
    key: "base_name",
  },
  {
    title: "创建人",
    key: "admin_username",
  },
  {
    title: "更新日期",
    render: (row) =>
      h(NTime, {
        time: (Number(row.update_time) || 0) * 1000,
      }),
  },
  {
    title: "用于授课数",
    key: "lecture_num",
  },
  {
    title: "分发院校数",
    key: "distribute_school",
  },
  {
    title: "状态",
    render: (row) =>
      h("span", null, { default: () => getResourceName(row.stop) }),
  },
  {
    title: "操作",
    width: 300,
    key: "stop",
    render: (row) =>
      h(NSpace, null, {
        default: () => Operation(row),
      }),
  },
];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

// 数据初始化
function updateList(data) {
  loading.value = true;
  // 请求列表数据
  GetCourseIndex(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list;
        updateItemCount(res.data.total);
        // console.log("请求列表数据3:",Datas)
      }
    })
    .catch((reject) => {});
}
updateList(toRaw(formValue));

// 状态
function getResourceName(stop) {
  return accountType[stop] || "";
}

//操作
function Operation(row) {
  let tempArr = [];
  let arr1 = h(
    NButton,
    { text: true, type: "primary", onClick: () => ViewDetails(row) },
    { default: () => "查看" }
  );
  let arr2 = h(
    NButton,
    {
      text: true,
      type: "primary",
      onClick: () => DistributionInstitution(0, row),
    },
    { default: () => "分发院校" }
  );
  let arr3 = h(
    NButton,
    { text: true, type: "primary", onClick: () => DisableEnable(row) },
    { default: () => "启用" }
  );
  let arr4 = h(
    NButton,
    { text: true, type: "primary", onClick: () => toAddLabel(row) },
    { default: () => "课程建设" }
  );
  let arr5 = h(
    NButton,
    { text: true, type: "error", onClick: () => toDelLabel(row) },
    { default: () => "删除" }
  );
  let arr6 = h(
    NButton,
    { text: true, type: "error", onClick: () => DisableEnable(row) },
    { default: () => "停用" }
  );
  if (row.stop == "0") {
    // 查看
    tempArr.push(arr1);
    // 分发院校
    if (ownUrlPermission("course/distribute")) {
      tempArr.push(arr2);
    }
    // 状态改变
    if (ownUrlPermission("course/state")) {
      tempArr.push(arr3);
    }
    // 课程建设
    if (ownUrlPermission("course/update")) {
      tempArr.push(arr4);
    }
    // 删除
    if (ownUrlPermission("course/delete")) {
      tempArr.push(arr5);
    }
    return tempArr;
  } else {
    // 查看
    tempArr.push(arr1);
    // 分发院校
    if (ownUrlPermission("course/distribute")) {
      tempArr.push(arr2);
    }
    // 状态改变
    if (ownUrlPermission("course/state")) {
      tempArr.push(arr6);
    }
    // 课程建设
    if (ownUrlPermission("course/update")) {
      tempArr.push(arr4);
    }
    return tempArr;
  }
}

// 课程建设
function toAddLabel(row) {
  let id = row.id;
  router.push({
    path: "/course/index/build",
    query: { id },
  });
}

// 课程建设弹窗参数
const bodyStyle = ref({
  width: "500px",
});

// 新增课程弹窗
const showModal = ref(false);

// 成功提示弹窗
const showModal1 = ref(false);

const courseName = ref(""); // 新增课程名称

// 新增课程点击事件
function CancelMethod(is, row) {
  courseName.value = "";
  if (is == 0) {
    showModal.value = false;
    showModal1.value = false;
    updateList(toRaw(formValue));
  } else {
    showModal.value = true;
  }
}

const courseID = ref("");
// 新增课程名称提交
function NewlyAdded(row) {
  loading.value = true;
  if (courseName.value != "") {
    let data = { "Course[base_name]": courseName.value };
    PostCourseCreate(data)
      .then((res) => {
        if (res.code === SUCCESS) {
          showModal.value = false;
          showModal1.value = true;
          courseID.value = res.data.id;
        }
        loading.value = false;
      })
      .catch((err) => {
        message.info(err);
      });
  } else {
    loading.value = false;
    message.info("课程名称不能为空");
  }
}

// 新增课程成功后的方法
// 新增课程名称提交
function addSuccess(is) {
  showModal.value = false;
  if (is) {
    router.push({
      path: "/course/index/build",
      query: { id: courseID.value },
    });
  }
}

// 删除
function toDelLabel(row) {
  const dialog = window.$dialog;
  let data = {
    "Course[ids]": [row.id],
  };
  dialog.warning({
    title: "删除确认",
    content: `确定删除课程:“${row.base_name}”？`,
    positiveText: "确定",
    negativeText: "取消",
    onPositiveClick: () => {
      loading.value = true;
      PostCourseDelete(data)
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            message.info(res.msg);
            // 成功后 调用接口刷新页面
            updateList(toRaw(formValue));
            // 删除后重置checked数据
            DefaultValue.value = [];
            TrainingBook.value = [];
            BookLength.value = [];
          }
        })
        .catch((reject) => {});
    },
    onNegativeClick: () => {},
  });

  // 账号删除接口
}

// 停用启用
function DisableEnable(row) {
  loading.value = true;
  var formDatas = new FormData();
  let data = {};
  data["Course[id]"] = row.id;
  data["Course[stop]"] = row.stop == "0" ? 1 : 0;
  PostCourseState(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.info(res.msg);
        // 成功后 调用接口刷新页面
        updateList(toRaw(formValue));
      }
    })
    .catch((reject) => {});
}

// 翻页 搜索参数集
var ParameterSet = {};

// 搜索
function handleSearch() {
  updateList(toRaw(formValue));
}

// 查看详情
function ViewDetails({ id }) {
  window.open(`/full-page/course-preview?cid=${id}`);
}

// 分发院校
function DistributionInstitution(is, row) {
  let data = [];
  // 分发
  if (is == 0) {
    // 如果没有选择批量分发 则赋值 TrainingBook
    TrainingBook.value = [];
    TrainingBook.value.push(row);
    data.push(row.id);
    distributeRef.value.openModal();
  }
  // 批量分发
  else {
    if (TrainingBook.value.length == 0) {
      message.info("多选后方可点击");
    } else {
      // console.log('批量分发',DefaultValue.value);
      // console.log('批量分发',BookLength.value);
      data = DefaultValue.value;
      distributeRef.value.openModal();
    }
  }
}

// 监控标题输入
function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;
};
const handlePageChange = (pageSize) => {
  pagination.pageSize = pageSize;
  formValue.pagesize = pageSize;
  ParameterSet = toRaw(formValue);
  updateList(ParameterSet);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue);
  updateList(ParameterSet);
};

// 分页结束-------------------

const getDefaultFormValue = () => ({
  title: "",
  stop: "",
  page: 1,
  pagesize: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);

const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  pagination.pageSize = 10;
  handleSearch();
};

// 分发
const distributeRef = ref(null);

const toDistribute = (row) => {
  // localStorage.setItem("schoolId",row.id);
  // localStorage.setItem("schoolName",row.school_name);
  distributeRef.value.openModal();
};

defineExpose({
  getSearchParams,
});
</script>

<style scoped>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-btn {
  float: right;
}
.account-null-btn {
  height: 50px;
  width: 120px;
}
.n-dialog__title {
  font-weight: 700 !important;
}
.storage-btns {
  float: right;
}
.storage-btn-kc {
  width: 180px;
}
</style>